@import "../../variables";

.footer {
  background: $color-bg-layout;
  border-top: 1px solid $color-border;

  .footer__content {
    max-width: 1220px;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
  }
}
