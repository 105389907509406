@import "../../../variables";

.send-access-report-with-back-btn {
  .info-modal {
    margin-bottom: 15px;
  }

  .back-button {
    float: left;
  }

  .users-list {
    height: auto;
    margin: 10px 0;

    tbody {
      background: $color-fill-tertiary; // was fafafa;
    }
  }
}
