@import "../../variables";

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.page--with-navbar {
    padding-top: 60px;
  }

  .page__body {
    flex: 1 1 auto;
    padding: 10px 30px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
  }

  .page__section {
    position: relative;
    background-color: $color-bg-base;
    padding: 30px 40px;
    border-radius: 2px;
    box-shadow: $box-shadow-tertiary;
    margin-top: 15px;
    overflow: hidden;

    .ant-slider {
      .ant-slider__arrow {
        &.ant-slider__arrow--next {
          right: -40px;
        }

        &.ant-slider__arrow--prev {
          left: 0;
        }
      }

      .ant-slider__menu {
        right: -40px;
        top: -30px;
        bottom: -30px;
      }

      .ant-slider__menu-backdrop {
        right: -40px;
        top: -30px;
        bottom: -30px;
        left: -40px;
      }
    }

    &.slider-section {
      padding-left: 0;
    }
  }

  .page__section__title {
    margin-bottom: 16px;
  }

  .page__header {
    background-color: $color-bg-base;
    padding: 26px 30px 20px;
    border-radius: 2px;
    box-shadow: $box-shadow-tertiary;
    margin: 15px 0;
    position: relative;

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .page__header-title {
      h1 {
        display: inline-flex;
        align-items: center;
        color: $color-text;
        font-size: 24px;
        margin: 0;

        & + p {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .anticon.hint {
      font-size: 16px;
      color: $color-text;
      margin-left: 10px;
    }

    hr {
      border: none;
      background: $color-bg-layout;
      height: 1px;
      margin: 20px -30px 20px;
    }
  }
}
