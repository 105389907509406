@import "../../variables";

.loader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $entromy-color-backdrop;
  z-index: 100;
}
