.survey-manage {
  .ant-table .ant-table-tbody .first-column {
    padding: 9px 7px;
  }

  .ant-table-row-level-0 {
    td:first-child {
      span .ant-btn-primary {
        margin-left: 24px;
      }

      .ant-table-row-expand-icon {
        padding-right: 12px;
      }
    }

    .parent {
      display: block;
    }
  }

  .ant-table-row-level-1 {
    .ant-table-row-expand-icon {
      display: none;
    }

    .child {
      padding-left: 40px;
      display: block;
    }
  }
}
