@import "../../variables";

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-bg-base;
  z-index: 10;
  padding: 5px 0;

  .navbar__caret {
    margin-bottom: 2px;
  }

  .navbar__content {
    max-width: 1440px;
    padding: 0 40px;
    width: 100%;
    margin: 0 auto;
  }

  .navbar__logo {
    height: 50px;
    width: auto;
    max-width: 220px;
    object-fit: contain;
  }

  .navbar__menu__item {
    text-align: center;
    position: relative;
    display: block;
    color: $color-text-secondary; // was 999c9e
    overflow: visible;
    height: auto;
    padding: 0;

    &:hover {
      color: $color-text;
      background-color: transparent !important;
    }

    &.navbar__menu__item--active {
      color: $color-primary;

      &:hover {
        color: $color-primary-active;
        background-color: transparent !important;
      }
    }

    .navbar__menu__item__badge {
      position: absolute;
      color: $color-bg-base;
      background-color: $color-primary;
      padding: 2px 5px;
      right: 0;
      top: -4px;
      font-size: 11px;
      line-height: 13px;
      border-radius: 2px;
    }
  }
}

.navbar__submenu {
  min-width: 150px;

  .ant-dropdown-menu-submenu-arrow {
    display: none;
  }
}

.navbar_orgs-submenu ul {
  width: 300px;
  max-height: 300px;
  overflow-y: auto;

  .navbar_orgs-submenu__item--active {
    background-color: $color-primary-active;
    color: $color-bg-base;
  }
}

.ant-dropdown-menu .ant-dropdown-menu-item.search-menu-item {
  .search-input {
    margin-bottom: 0;
  }

  &:hover {
    background: transparent;
  }
}
