// Important!
// every time you need to add here something -
// try to find similar variable in Antd
// Only if can not - hardcode but on the very bottom of the file

//// COLORS - first color in the comments - just for reference without alpha on the white bg
$color-bg-base: #{var(--ant-color-bg-base)}; // #ffffff; orig: #fff;
$color-bg-container: #{var(--ant-color-bg-container)}; // #ffffff;
$color-bg-container-disabled: #{var(--ant-color-bg-container-disabled)}; // #f5f5f5; orig: rgba(0, 0, 0, 0.04);
$color-bg-elevated: #{var(--ant-color-bg-elevated)}; // #ffffff;
$color-bg-layout: #{var(--ant-color-bg-layout)}; // #f5f5f5;
$color-bg-mask: #{var(--ant-color-bg-mask)}; // #8d8d8d; orig: rgba(0, 0, 0, 0.45);
$color-bg-spotlight: #{var(--ant-color-bg-spotlight)}; // #272727; orig: rgba(0, 0, 0, 0.85);
$color-bg-text-active: #{var(--ant-color-bg-text-active)}; // #d9d9d9; orig: rgba(0, 0, 0, 0.15);
$color-bg-text-hover: #{var(--ant-color-bg-text-hover)}; // #f0f0f0; orig: rgba(0, 0, 0, 0.06);
$color-border: #{var(--ant-color-border)}; // #d9d9d9;
$color-border-bg: #{var(--ant-color-border-bg)}; // #ffffff;
$color-border-secondary: #{var(--ant-color-border-secondary)}; // #f0f0f0;
$color-error: #{var(--ant-color-error)}; // #ff4d4f;
$color-error-active: #{var(--ant-color-error-active)}; // #d9363e;
$color-error-bg: #{var(--ant-color-error-bg)}; // #fff2f0;
$color-error-bg-hover: #{var(--ant-color-error-bg-hover)}; // #fff1f0;
$color-error-border: #{var(--ant-color-error-border)}; // #ffccc7;
$color-error-border-hover: #{var(--ant-color-error-border-hover)}; // #ffa39e;
$color-error-hover: #{var(--ant-color-error-hover)}; // #ff7875;
$color-error-outline: #{var(--ant-color-error-outline)}; // #fff2f0; orig: rgba(255, 38, 5, 0.06);
$color-error-text: #{var(--ant-color-error-text)}; // #ff4d4f;
$color-error-text-active: #{var(--ant-color-error-text-active)}; // #d9363e;
$color-error-text-hover: #{var(--ant-color-error-text-hover)}; // #ff7875;
$color-fill: #{var(--ant-color-fill)}; // #d9d9d9; orig: rgba(0, 0, 0, 0.15);
$color-fill-alter: #{var(--ant-color-fill-alter)}; // #fafafa; orig: rgba(0, 0, 0, 0.02);
$color-fill-content: #{var(--ant-color-fill-content)}; // #f0f0f0; orig: rgba(0, 0, 0, 0.06);
$color-fill-content-hover: #{var(--ant-color-fill-content-hover)}; // #d9d9d9; orig: rgba(0, 0, 0, 0.15);
$color-fill-quaternary: #{var(--ant-color-fill-quaternary)}; // #fafafa; orig: rgba(0, 0, 0, 0.02);
$color-fill-secondary: #{var(--ant-color-fill-secondary)}; // #f0f0f0; orig: rgba(0, 0, 0, 0.06);
$color-fill-tertiary: #{var(--ant-color-fill-tertiary)}; // #f5f5f5; orig: rgba(0, 0, 0, 0.04);
$color-highlight: #{var(--ant-color-highlight)}; // #ff4d4f;
$color-icon: #{var(--ant-color-icon)}; // #8d8d8d; orig: rgba(0, 0, 0, 0.45);
$color-icon-hover: #{var(--ant-color-icon-hover)}; // #1f1f1f; orig: rgba(0, 0, 0, 0.88);
$color-info: #{var(--ant-color-info)}; // #1677ff;
$color-info-active: #{var(--ant-color-info-active)}; // #0958d9;
$color-info-bg: #{var(--ant-color-info-bg)}; // #e6f4ff;
$color-info-bg-hover: #{var(--ant-color-info-bg-hover)}; // #bae0ff;
$color-info-border: #{var(--ant-color-info-border)}; // #91caff;
$color-info-border-hover: #{var(--ant-color-info-border-hover)}; // #69b1ff;
$color-info-hover: #{var(--ant-color-info-hover)}; // #69b1ff;
$color-info-text: #{var(--ant-color-info-text)}; // #1677ff;
$color-info-text-active: #{var(--ant-color-info-text-active)}; // #0958d9;
$color-info-text-hover: #{var(--ant-color-info-text-hover)}; // #4096ff;
$color-link: #{var(--ant-color-link)}; // #459102;
$color-link-active: #{var(--ant-color-link-active)}; // #2e6b00;
$color-link-hover: #{var(--ant-color-link-hover)}; // #75ab38;
$color-primary: #{var(--ant-color-primary)}; // #459102;
$color-primary-active: #{var(--ant-color-primary-active)}; // #2e6b00;
$color-primary-bg: #{var(--ant-color-primary-bg)}; // #f5ffec;
$color-primary-bg-hover: #{var(--ant-color-primary-bg-hover)}; // #a9c480;
$color-primary-border: #{var(--ant-color-primary-border)}; // #8fb85a;
$color-primary-border-hover: #{var(--ant-color-primary-border-hover)}; // #75ab38;
$color-primary-hover: #{var(--ant-color-primary-hover)}; // #5c9e1b;
$color-primary-text: #{var(--ant-color-primary-text)}; // #459102;
$color-primary-text-active: #{var(--ant-color-primary-text-active)}; // #2e6b00;
$color-primary-text-hover: #{var(--ant-color-primary-text-hover)}; // #5c9e1b;
$color-split: #{var(--ant-color-split)}; // #f0f0f0; orig: rgba(5, 5, 5, 0.06);
$color-success: #{var(--ant-color-success)}; // #52c41a;
$color-success-active: #{var(--ant-color-success-active)}; // #389e0d;
$color-success-bg: #{var(--ant-color-success-bg)}; // #f6ffed;
$color-success-bg-hover: #{var(--ant-color-success-bg-hover)}; // #d9f7be;
$color-success-border: #{var(--ant-color-success-border)}; // #b7eb8f;
$color-success-border-hover: #{var(--ant-color-success-border-hover)}; // #95de64;
$color-success-hover: #{var(--ant-color-success-hover)}; // #95de64;
$color-success-text: #{var(--ant-color-success-text)}; // #52c41a;
$color-success-text-active: #{var(--ant-color-success-text-active)}; // #389e0d;
$color-success-text-hover: #{var(--ant-color-success-text-hover)}; // #73d13d;
$color-text: #{var(--ant-color-text)}; // #1f1f1f; orig: rgba(0, 0, 0, 0.88);
$color-text-base: #{var(--ant-color-text-base)}; // #000000; orig: #000;
$color-text-description: #{var(--ant-color-text-description)}; // #8d8d8d; orig: rgba(0, 0, 0, 0.45);
$color-text-disabled: #{var(--ant-color-text-disabled)}; // #c0c0c0; orig: rgba(0, 0, 0, 0.25);
$color-text-heading: #{var(--ant-color-text-heading)}; // #1f1f1f; orig: rgba(0, 0, 0, 0.88);
$color-text-label: #{var(--ant-color-text-label)}; // #595959; orig: rgba(0, 0, 0, 0.65);
$color-text-light-solid: #{var(--ant-color-text-light-solid)}; // #ffffff; orig: #fff;
$color-text-placeholder: #{var(--ant-color-text-placeholder)}; // #c0c0c0; orig: rgba(0, 0, 0, 0.25);
$color-text-quaternary: #{var(--ant-color-text-quaternary)}; // #c0c0c0; orig: rgba(0, 0, 0, 0.25);
$color-text-secondary: #{var(--ant-color-text-secondary)}; // #595959; orig: rgba(0, 0, 0, 0.65);
$color-text-tertiary: #{var(--ant-color-text-tertiary)}; // #8d8d8d; orig: rgba(0, 0, 0, 0.45);
$color-warning: #{var(--ant-color-warning)}; // #faad14;
$color-warning-active: #{var(--ant-color-warning-active)}; // #d48806;
$color-warning-bg: #{var(--ant-color-warning-bg)}; // #fffbe6;
$color-warning-bg-hover: #{var(--ant-color-warning-bg-hover)}; // #fff1b8;
$color-warning-border: #{var(--ant-color-warning-border)}; // #ffe58f;
$color-warning-border-hover: #{var(--ant-color-warning-border-hover)}; // #ffd666;
$color-warning-hover: #{var(--ant-color-warning-hover)}; // #ffd666;
$color-warning-outline: #{var(--ant-color-warning-outline)}; // #fffbe6; orig: rgba(255, 215, 5, 0.1);
$color-warning-text: #{var(--ant-color-warning-text)}; // #faad14;
$color-warning-text-active: #{var(--ant-color-warning-text-active)}; // #d48806;
$color-warning-text-hover: #{var(--ant-color-warning-text-hover)}; // #ffc53d;
$control-item-bg-active: #{var(--ant-control-item-bg-active)}; // #f5ffec;
$control-item-bg-active-disabled: #{var(--ant-control-item-bg-active-disabled)}; // #d9d9d9; orig: rgba(0, 0, 0, 0.15);
$control-item-bg-active-hover: #{var(--ant-control-item-bg-active-hover)}; // #a9c480;
$control-item-bg-hover: #{var(--ant-control-item-bg-hover)}; // #f5f5f5; orig: rgba(0, 0, 0, 0.04);
$control-outline: #{var(--ant-control-outline)}; // #f5ffec; orig: rgba(130, 255, 17, 0.08);
$control-tmp-outline: #{var(--ant-control-tmp-outline)}; // #fafafa; orig: rgba(0, 0, 0, 0.02);
$option-selected-bg: #{var(--ant-option-selected-bg)};
$option-selected-color: #{var(--ant-option-selected-color)};

// SIZES
$font-size-heading-1: #{var(--ant-font-size-heading-1)}; // 38px;
$font-size-heading-2: #{var(--ant-font-size-heading-2)}; // 30px;
$font-size-heading-3: #{var(--ant-font-size-heading-3)}; // 24px;
$font-size-heading-4: #{var(--ant-font-size-heading-4)}; // 20px;
$font-size-lg: #{var(--ant-font-size-lg)}; // 16px;
$font-size: #{var(--ant-font-size)}; // 14px;
$font-size-sm: #{var(--ant-font-size-sm)}; // 12px
$opacity-loading: #{var(--ant-opacity-loading)}; // 0.65
$screen-lg-max: #{var(--ant-screen-lg-max)}; // 1200px was. 1199px now
$screen-lg-min: #{var(--ant-lg-min)}; // 992px;
$screen-md-max: #{var(--ant-md-max)}; // 991px;
$screen-md-min: #{var(--ant-md-min)}; // 768px;
$screen-sm-max: #{var(--ant-screen-md-max)}; // 767px;
$screen-sm-min: #{var(--ant-screen-md-min)}; // 576px;
$screen-xs-max: #{var(--ant-screen-xs-max)}; // 575px;
$screen-xxs-max: #{var(--ant-screen-xxs-max)}; // 480px;

//// MISC
$border-radius: #{var(--ant-border-radius)}; // 4px;
$box-shadow-tertiary: #{var(--ant-box-shadow-tertiary)};
$box-shadow: #{var(--ant-box-shadow)};
$font-family: #{var(--ant-font-family)}; // Arial, Helvetica, sans-serif;

// Entromy, put variables here only if can not find similar color in antd

// Antd has colors for this block, but
// sometimes we add styles above app root, or in not styled notification or alerts
$entromy-color-link-hover: #75ab38;
$entromy-color-bg: #f5f5f5;
$entromy-color-primary: #459102;

// New variables from entromy
$entromy-color-backdrop: rgb(255 255 255 / 50%);
$entromy-font-size-xs: 10px; // TODO: should we switch to smaller $font-size?

// Reports
$entromy-reports-promoters: #cce2a6; // no similar color
$entromy-reports-detractors: #a73437; // no similar color

// Reports BusinessContextScores
$entromy-reports-business-context-scores-bar-1: #aa3a3d; // ? $color-error-active
$entromy-reports-business-context-scores-bar-2: #d6cfcf; // no similar color
$entromy-reports-business-context-scores-bar-3: #f6d6bb; // ? colorErrorBorder far from color
$entromy-reports-business-context-scores-bar-4: #ddd094; // ? color-warning-border far from color
$entromy-reports-business-context-scores-bar-5: #9cc54e; // ? $color-primary-border

// Reports DetractorsByUserDemographics
$entromy-reports-detractors-by-user-demographics-chart-color: #ccc; // ? $color-text-disabled - but it is for text and with opacity
$entromy-reports-detractors-by-user-demographics-detractors-color: #d99694; // ? color-error-border-hover
$entromy-reports-detractors-by-user-demographics-detractors-neutral: #ddd; // ? $color-border

// Reports KeyDrivers
$entromy-reports-key-drivers-color-bg: #386092; // no similar color

// Reports MultiSelect
$entromy-reports-multi-select-bar-bg-color: #768db5; // no similar color in antd

// Reports Detractors
$entromy-reports-detractors-challenge-bg-color: rgb(217 167 169); // no similar color
$entromy-reports-detractors-recs-bg-color: rgb(220 236 246); // no similar color
