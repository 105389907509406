@import "../../../variables";

.user-modal {
  .password-feedback {
    margin-left: 220px;
    text-align: left;

    .valid {
      color: $color-primary;
    }

    .invalid {
      color: $color-text;
    }
  }
}

.user-modal__access {
  .user-modal__access__survey-select {
    position: relative;

    .access__survey-select__toggle {
      position: absolute;
    }

    .ant-select-selector {
      height: 32px;
      width: 32px;
      padding: 0;
      border: none;
      background: transparent;
      cursor: pointer !important;

      .ant-select-selection-search,
      .ant-select-selection-overflow {
        display: none;
      }
    }
  }

  .access_group-select {
    width: 100%;
  }
}

#user-form label {
  height: auto;
  min-height: 32px;
  white-space: normal;
  word-break: break-word;
}

.user-modal__surveys-dropdown {
  .search-input {
    margin-bottom: 0;
  }

  .user-modal__surveys-dropdown__search {
    padding: 5px 12px;
  }
}

.history-table {
  .ant-table {
    font-size: $font-size-sm;
  }
}
