.cke_screen_reader_only.cke_copyformatting_notification {
  bottom: 0;
}

.ck-button.ck-dropdown__button {
  .custom-dropdown-no-arrow {
    width: auto !important;
  }

  .ck-button__label {
    width: auto !important;
  }
}
